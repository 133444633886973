import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"

import styled from 'styled-components'

import Container from '../components/Container'
import Layout from '../components/Layout'

export default () => {
	const data = useStaticQuery(query)

	return (
		<Layout>
			<Container>
				<section>
					<Title>History of DiTan</Title>
					<HistoryOfDitanImg fluid={ data.aboutTwo.childImageSharp.fluid } alt="Chinese characters" />
					<p>
						The original name for DiTan Wu Shu Contortion School was The American Wu Shu Society (AWS), which was founded in 1989. In 2003, AWS 
						became a 501(c) not-for-profit organization and our operating school name became DiTan Wu Shu Sanda Guan till 2012. 
						Now it's finalized as DiTan Wu Shu Contortion School.
					</p>
					<p>
						DiTan translates to Earth Temple and is a landmark area in Beijing 
						China where my coach <WuBinLink href="https://en.wikipedia.org/wiki/Wu_Bin_(wushu_coach)" target="_blank" rel="noopener">Grandmaster Wu Bin</WuBinLink> had a school named after him, after he retired from coaching the famous Beijing Wu Shu Team. With his permission, I brought the name DiTan to NYC and 
						teach with the same professionalism and integrity that he entrusted onto me.
					</p>
				</section>
				<section>
					<Title>Meaning of Wu</Title>
					<MeaningOfWushuImg fluid={ data.aboutOne.childImageSharp.fluid } alt="Meaning of Wu Shu chinese characters" />
					<p>
						Wu Shu or Chinese Kungfu, is a hard and complete martial art, as well as a full-contact sport. It has a long history 
						in reference to Chinese martial arts. It was developed in China after 1949 in an effort to standardize the practice of
						traditional Chinese martial arts, yet attempts to structure the various decentralized martial arts traditions date back earlier,
						when the Central Guoshu Institute was established at Nanking in 1928.
					</p>
					<p>
						In contemporary times, Wu Shu has
						become an international sport through the International Wu Shu Federation (IWUF), which holds the World Wu Shu Championships every
						two years; the first World Championships were held in 1991 in Beijing. The World Kungfu Championships are held every four years
						subset International Wu Shu Federation, as well.
					</p>
					<p>
						Competitive Wu Shu is composed of two disciplines: taolu (套路; forms) and sanda (散打; sparring). But it has other disciplines,
						like self-defense, breaking hard objects, and other related practices, that are not performed in competitions.
					</p>
					<p>
						Taolu involves martial art patterns, acrobatic movements and techniques for which competitors are judged and given points according
						to specific rules. The forms comprise basic movements (stances, kicks, punches, balances, jumps, sweeps, and throws) based on aggregate
						categories of traditional Chinese martial art styles, and can be changed for competitions to highlight one's strengths. Competitive forms
						have time limits that can range from 1 minute, 20 seconds for some external styles, to over five minutes for internal styles.
					</p>
					<p>
						Sanda (sometimes called sanshou) is a modern fighting method and a full contact sport. Sanda contains boxing, kicks (kickboxing),
						and wrestling. It has all the combat aspects of Wu Shu. Sanda appears much like Kickboxing, MMA, Boxing or Muay Thai, but includes
						many more grappling techniques. Sanda fighting competitions are often held alongside taolu.
					</p>
				</section>
				<section>
					<Title>History</Title>
					<p>
						In 1958, the government established the All-China Wu Shu Association as an umbrella organization to regulate martial arts training.
						The Chinese State Commission for Physical Culture and Sports took the lead in creating standardized forms for most of the major arts.
						During this period, a national Wu Shu system that included standard forms, teaching curriculum, and instructor grading was established.
						Wu Shu was introduced at both the high school and university level. This new system seeks to incorporate common elements from all styles
						and forms as well as the general ideas associated with Chinese martial arts. Stylistic concepts such as hard, soft, internal, external, as
						well as classifications based on schools such as Shaolin, Taiji, Wudang and others were all integrated into one system. Wu Shu became 
						the government sponsored standard for the training in martial arts in China. The push for standardization continued leading to 
						widespread adaptation. In 1979, the State Commission for Physical Culture and Sports created a special task force to teaching and 
						practice of Wu Shu. In 1986, the Chinese National Research Institute of Wu Shu was established as the central authority for the research 
						and administration of Wu Shu activities in China.
					</p>
					<p>
						Changing government policies and attitudes towards sports in general lead to the closing of the State Sports Commission (the central 
						sports authority) in 1998. This closure is viewed as an attempt to partially de-politicize organized sports and move Chinese sport 
						policies towards a more market-driven approach. As a result of these changing sociological factors within China, both traditional 
						styles and modern Wu Shu approaches are being promoted by the International Wu Shu Federation.
					</p>
				</section>
				<section>
					<Title>Contemporary Taolu Events</Title>
					<p>
						Wu Shu events are performed using compulsory or individual routines in competition. Compulsory routines are those routines that have been already 
						created for the athlete, resulting in each athlete performing basically the same set. Individual routines are routines that an athlete creates with 
						the aid of his/her coach, while following certain rules for difficulty. In addition to events for individual routines, some Wu Shu competitions 
						also feature dual and group events. The dual event, also called duilian (对练), is an event in which there is some form of sparring with weapons, 
						or without weapons or even using bare hands against weapons. The dual event is usually spectacular and actions are choreographed beforehand. 
						The group event, also known as jiti (集體), requires a group of people to perform together and smooth synchronization of actions are crucial. 
						Usually, the group event also allows instrumental music to accompany the choreography during the performance. The carpet used for the group event 
						is also larger than the one used for individual routines. Previously, international Wu Shu competitions most often used compulsory routines, 
						while high-level competitions in China most often used individual routines. However, after the 2003 Wu Shu World Games in Macau it was 
						decided to opt for individual routines in international competition with nandu (難度; difficulty movements) integrating a maximum 2 point nandu 
						score into the overall maximum score of 10. There is some controversy concerning the inclusion of nandu in Wu Shu because many of the movements 
						created for the specific events are not originally movements used in those styles. In addition, the number of injuries which have resulted from 
						the inclusion of these nandu have caused many people to question their inclusion. Those who support the new difficulty requirements follow the 
						assertion that they help to progress the sport and improve the overall physical quality of the athletes.
					</p>
				</section>
			</Container>
		</Layout>
	)
}

const WuBinLink = styled.a`
	color: hsl(356, 65%, 48%);
	text-decoration: none;
`
const HistoryOfDitanImg = styled(Img)`
	float: right;
	max-width: 400px;
	width: 100%;
	margin: 10px
`
const MeaningOfWushuImg = styled(Img)`
	float: left;
	max-width: 400px;
	width: 100%;
	margin: 10;
`
const Title = styled.h3`
	color: hsl(356, 45%, 48%);
`

const query = graphql`
	query {
		aboutOne: file(relativePath: { eq: "about/about_1.png" }) {
			childImageSharp {
				fluid(maxWidth: 1019) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		aboutTwo: file(relativePath: { eq: "about/about_2.png" }) {
			childImageSharp {
				fluid(maxWidth: 1412) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}
	}
`